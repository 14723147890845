<template>
  <div id="user-profile">
    <b-card v-if="!result && !processing">
      <b-card-text>
        {{ $t('components.cards.meli.settings.import.start_import_info') }}
      </b-card-text>
      <b-row>
        <b-col cols="12" md="2">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" :disabled="formSubmitted"
            @click.stop="startImport">
            <font-awesome-icon v-if="!formSubmitted" :icon="['fas', 'cloud-download-alt']" fixed-width />
            <font-awesome-icon v-else :icon="['fas', 'spinner']" pulse fixed-width />
            {{ $t('components.cards.meli.settings.import.start_import') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card v-if="result && result.orders === 1 && result.payments === 1">
      <b-card-text class="text-success">
        <font-awesome-icon :icon="['fas', 'check']" fixed-width /> {{ $t('components.cards.meli.settings.import.restart_import_info', {
           modified: importDate}) }}
      </b-card-text>
      <b-row>
        <b-col cols="12" md="2">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" :disabled="formSubmitted"
            @click.stop="startImport">
            <font-awesome-icon v-if="!formSubmitted" :icon="['fas', 'cloud-download-alt']" fixed-width />
            <font-awesome-icon v-else :icon="['fas', 'spinner']" pulse fixed-width />
            {{ $t('components.cards.meli.settings.import.restart_import') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <table-info :showLoading="!result && processing"></table-info>
    <b-card v-if="result && (result.orders !== 1 || result.payments !== 1)">
      <b-card-text class="text-info">
        <font-awesome-icon :icon="['fas', 'info-circle']" fixed-width /> {{ $t('components.cards.meli.settings.import.import_processing') }}
      </b-card-text>
    </b-card>

    <b-row v-if="result" class="match-height">
      <b-col md="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title class="text-primary">
              <font-awesome-icon :icon="['fas', 'shopping-cart']" fixed-width /> {{ $t('components.cards.meli.settings.import.orders') }}
            </b-card-title>
          </b-card-header>
          <vue-apex-charts height="250" type="radialBar" :options="cardImportOptions.chartOptions" :series="ordersProcessed" />
          <b-row class="text-center mx-0">
            <b-col cols="6" class="border-top border-right d-flex align-items-between flex-column py-1">
              <b-card-text class="text-warning mb-0">
                {{ $t('components.cards.meli.settings.import.processed') }}
              </b-card-text>
              <h3 class="font-weight-bolder text-warning mb-0">
                {{ formatNumber(result.orders_processed, 0) }}
              </h3>
            </b-col>
            <b-col cols="6" class="border-top d-flex align-items-between flex-column py-1">
              <b-card-text class="text-info mb-0">
                {{ $t('components.cards.meli.settings.import.total') }}
              </b-card-text>
              <h3 class="font-weight-bolder text-info mb-0">
                {{ formatNumber(result.orders_total, 0) }}
              </h3>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title class="text-primary">
              <font-awesome-icon :icon="['fas', 'dollar-sign']" fixed-width /> {{ $t('components.cards.meli.settings.import.payments') }}
            </b-card-title>
          </b-card-header>
          <vue-apex-charts height="250" type="radialBar" :options="cardImportOptions.chartOptions"
            :series="paymentsProcessed" />
          <b-row class="text-center mx-0">
            <b-col cols="6" class="border-top border-right d-flex align-items-between flex-column py-1">
              <b-card-text class="text-warning mb-0">
                {{ $t('components.cards.meli.settings.import.processed') }}
              </b-card-text>
              <h3 class="font-weight-bolder text-warning mb-0">
                {{ formatNumber(result.payments_processed, 0) }}
              </h3>
            </b-col>
            <b-col cols="6" class="border-top d-flex align-items-between flex-column py-1">
              <b-card-text class="text-info mb-0">
                {{ $t('components.cards.meli.settings.import.total') }}
              </b-card-text>
              <h3 class="font-weight-bolder text-info mb-0">
                {{ formatNumber(result.payments_total, 0) }}
              </h3>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BButton, BRow, BCol,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Crypt from '@core-md/utils/crypt'
import TableInfo from '@core-md/components/TableInfo.vue'
import handleAxiosException from '@core-md/mixins/errors'
import variants from '@core-md/mixins/variants'
import stringHelper from '@core-md/mixins/stringHelper'
import VueApexCharts from 'vue-apexcharts'
import { $themeConfig } from '@themeConfig'
import { format } from 'date-fns'
import { useWindowScroll } from '@vueuse/core'
import cardImportOptions from './_cardImportOptions'
import meliSettings from '@/services/meli/settings/client'

export default {
  components: {
    BCardHeader,
    BCard,
    BCardTitle,
    BCardText,
    BButton,
    BRow,
    BCol,
    VueApexCharts,
    TableInfo,
  },
  props: {
    account: {
      type: Object,
      default: null,
    },
    inherit: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [
    variants,
    handleAxiosException,
    stringHelper,
  ],
  data() {
    return {
      selectedAccount: null,
      result: null,
      cardImportOptions,
      formSubmitted: false,
      processing: false,
      unWatchSelectedAccount: null,
    }
  },
  computed: {
    accountLoaded() {
      return this.selectedAccount.loading >= 100
    },
    ordersProcessed() {
      const processed = []
      if (this.selectedAccount && this.selectedAccount.seller && this.result) {
        if (this.result.orders === 1 && this.result.orders_processed === 0 && this.result.orders_total === 0) {
          processed.push(100)
        } else {
          processed.push(this.formatNumber((this.result.orders_processed / this.result.orders_total) * 100, 0))
        }
      }
      return processed
    },
    paymentsProcessed() {
      const processed = []
      if (this.selectedAccount && this.selectedAccount.seller && this.result) {
        if (this.result.payments === 1 && this.result.payments_processed === 0 && this.result.payments_total === 0) {
          processed.push(100)
        } else {
          processed.push(this.formatNumber((this.result.payments_processed / this.result.payments_total) * 100, 0))
        }
      }
      return processed
    },
    importDate() {
      if (this.result) {
        return format(new Date(this.result.modified), 'dd/MM/yyyy')
      }
      return ''
    },
  },
  setup() {
    const { spinnerType } = $themeConfig.layout

    const { y } = useWindowScroll()

    return {
      y,
      spinnerType,
    }
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
          this.result = null
          this.getData()
        }
      })
    }
    this.$store.dispatch('meli/initialize')
    this.getData()
  },
  beforeDestroy() {
    if (this.unWatchSelectedAccount) {
      this.unWatchSelectedAccount()
      this.unWatchSelectedAccount = null
    }
  },
  methods: {
    getData() {
      if (this.selectedAccount) {
        this.results = []
        this.processing = true
        const request = {
          accessToken: this.selectedAccount.access_token,
          sellerId: this.selectedAccount.seller_id,
          type: 'meli',
        }
        meliSettings.getImports(request).then(response => {
          if (response.status === 200) {
            const result = Crypt.decrypt(response.data.result)
            this.result = result
            if (this.result && (this.result.orders === 2 || this.result.payments === 2)) {
              setTimeout(() => {
                this.getData()
              }, 10000)
            }
          }
          this.processing = false
        }).catch(data => {
          console.log(data)
          this.processing = false
        })
      }
    },
    startImport() {
      if (this.selectedAccount) {
        this.formSubmitted = true
        const request = {
          accessToken: this.selectedAccount.access_token,
          sellerId: this.selectedAccount.seller_id,
          type: 'meli',
          payments: 2,
          payments_processed: 0,
          payments_total: 0,
          orders: 2,
          orders_processed: 0,
          orders_total: 0,
        }
        meliSettings.saveImports(request).then(response => {
          if (response.status === 200) {
            const result = Crypt.decrypt(response.data.result)
            if (result && result.status === '0') {
              this.$root.showMessageSuccess(result.statusMessage, this.$i18n.t('common.messages.success'))
              this.getData()
            } else {
              this.$root.showMessageError(result.statusMessage, this.$i18n.t('common.messages.error'))
            }
          }
          this.formSubmitted = false
        }).catch(data => {
          console.log(data)
          this.formSubmitted = false
        })
      }
    },
    changeSelectedAccount(selected) {
      this.selectedAccount = selected
      this.getData()
    },
  },
}
</script>
