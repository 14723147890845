<template>
  <div>
  <b-card no-body>
    <HTMLTitle :title="$t('components.cards.meli.settings.import.page_title')"></HTMLTitle>

    <card-header :title="$t('components.cards.meli.settings.import.page_title')"
      :id="'meliImports'"
      :icon="['fas', 'cloud-download-alt']"
      :subTitle="(selectedAccount && selectedAccount.nickname) || $t('components.cards.common.select_account')"
      :description="$t('components.cards.meli.settings.import.page_info')"></card-header>

    </b-card>

    <card-import :options="options" />

  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import HTMLTitle from '@core-md/layouts/components/HTMLTitle.vue'
import CardHeader from '@core-md/components/cards/CardHeader.vue'
import CardImport from '@/layouts/components/cards/meli/settings/imports/CardImport.vue'

export default {
  components: {
    HTMLTitle,
    CardHeader,
    BCard,
    CardImport,
  },
  props: {
    account: {
      type: Object,
      default: null,
    },
    inherit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: {
        showTitle: false,
        showAccount: false,
        showInfo: false,
      },
      selectedAccount: null,
      unWatchSelectedAccount: null,
    }
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
        }
      })
    }
  },
  beforeDestroy() {
    if (this.unWatchSelectedAccount) {
      this.unWatchSelectedAccount()
      this.unWatchSelectedAccount = null
    }
  },
}
</script>
